@import "fonts";

// Form inputs
.react-select__input > input {
  @apply focus:ring-0;
}

[type="tel"] {
  @apply block w-full rounded-md border border-gray-200 py-2 px-3 shadow-sm transition focus:border-primary-300 focus:outline-none focus:ring-4 focus:ring-primary-50 sm:text-sm sm:leading-5;
}

[type="checkbox"] {
  @apply focus:ring-0;
}

.removeFormStyle input:focus {
  @apply border-0 shadow-none outline-none ring-0;
}

.no-scroll {
  overflow: hidden;
}

.h-navscreen {
  @apply h-[calc(100vh-58px)] xl:h-[calc(100vh-61px)];
}

.h-navscreenmobile {
  @apply sm:h-[calc(100vh-58px)] xl:h-[calc(100vh-61px)];
}

.h-resume {
  @apply lg:h-[calc(100vh-68px)];
}

.h-companies {
  @apply sm:h-[calc(100vh-110px)] lg:h-[calc(100vh-70px)];
}

.h-tracker {
  @apply h-[calc(100vh-320px)] xl:h-[calc(100vh-300px)];
}

// Recaptcha
.hide-recaptcha {
  .grecaptcha-badge {
    visibility: hidden;
  }
}

// Carousel
.control-dots {
  bottom: -15px !important;
}

// Marquee
.override .marquee-container {
  &:hover div {
    animation-play-state: var(--play);
  }
  &.hover div {
    animation-play-state: var(--pause-on-hover);
  }
}

// React PDF
.react-pdf__Page {
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
}

.react-pdf__Page__canvas {
  display: block !important;
  user-select: none !important;
  width: 100% !important;
  height: auto !important;
}

.react-pdf__Document {
  &.previous-document {
    canvas {
      opacity: 0.5;
    }
  }
}
.react-pdf__Page__annotations {
  &.annotationLayer {
    width: 100% !important;
    height: 100% !important;
  }
}
.react-pdf__Page__textContent {
  &.textLayer {
    width: 100% !important;
    height: 100% !important;
  }
}

.rfm-initial-child-container {
  align-items: baseline !important;
}

.override.center {
  .rfm-initial-child-container {
    align-items: center !important;
  }
}

// Tiptap
.ProseMirror {
  a {
    color: #3ec0de !important;
    text-decoration: underline !important;
    cursor: pointer !important;
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-bottom: 10px;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-bottom: 8px;
    font-weight: bold;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin-bottom: 6px;
    font-weight: bold;
  }
  pre {
    color: #24292e;
    background: #f5f7f9;
    font-family: "ui-monospace", "SFMono-Regular", "SF Mono", Menlo, Consolas, "Liberation Mono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
  }

  code {
    color: #616161;
    border-radius: 3px;
    padding: 1px;
    background: #f5f7f9;
    padding: 4px;
    border-radius: 0.5rem;
  }

  blockquote {
    border-left: 3px solid rgba(13, 13, 13, 0.1);
    padding-left: 1rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }
}

.ProseMirror:focus-visible {
  outline: none !important;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #6b7280;
  font-size: 16px;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror .column-block {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 24px;
  padding: 8px 0;
}

.ProseMirror .column {
  overflow: hidden;
  padding: 8px;
  margin: -8px;
  border: 1px gray dashed;
  border-radius: 8px;
}

//React-Datepicker
.react-datepicker__close-icon:after {
  background-color: white !important;
  color: #263137 !important;
  font-size: 16px !important;
  content: "x" !important;
  padding: 0px 0px 2px 0px !important;
}

.react-datepicker__portal {
  background-color: #6b7280bf !important;
}

// FrigadeTour
.fr-carouselContainer > div {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 20px !important;
  flex-wrap: wrap !important;
  gap: 16px !important;
  @media (max-width: 640px) {
    justify-content: left !important;
  }
}

#canny-changelog-iframe {
  @media (min-width: 500px) and (max-width: 640px) {
    left: 50px !important;
  }
}

.Canny_Badge {
  background-color: #e11d48 !important;
}

@keyframes animation-blink-outline {
  0%,
  100% {
    outline-color: transparent;
    outline-width: 0px;
  }
  50% {
    outline-color: #3ec0dd;
    outline-width: 4px;
  }
}

.blink-animation {
  animation: animation-blink-outline 1s ease-in-out 5;
}
